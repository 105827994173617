import { locales, pathnames,defaultLocale } from "@repo/i18n/locales"
import { defineRouting } from "next-intl/routing"
import {createNavigation} from 'next-intl/navigation';
export const routing = defineRouting({
	locales,
	pathnames,
	defaultLocale,
	// 完全依赖url的locale detection禁用cookie detection。避免用户在输入url时，因为cookie导致切换到cookie记录的语言
	localeDetection: false,
	// as-needed means that the locale prefix is only added to the pathname if it is not already present.
	localePrefix:{
		mode:"as-needed"
	}
})

export const { Link, getPathname, redirect, usePathname, useRouter } = createNavigation(routing)
export function getPathnameWithLocale(pathname: string, locale: string) {
	if (locale === "en") {
		return pathname
	}
	if (pathname.startsWith("/")) {
		return `/${locale}${pathname}`
	}
	return pathname
}
