import type { Pathnames } from "next-intl/routing"

export const EN = "en"
export const ZH_CN = "zh-CN"
export const ZH_TW = "zh-TW"
export const FR = "fr"  
export const DE = "de"
export const JA = "ja"
export const CS = "cs"
export const ES = "es"
export const IT = "it"
export const KO = "ko"
export const NL = "nl"
export const PT_BR = "pt-BR"
export const RU = "ru"
export const UK = "uk"
export const VI = "vi"
export const PT = "pt"
export const DA = "da"
export const EL = "el"
export const NO = "no"
export const FI = "fi"
export const SV = "sv"
export const TH = "th"
export const ID = "id"
export const HI = "hi"
export const BN = "bn"
export const MS = "ms"
export const TR = "tr"
export const AR = "ar"
export const BG = "bg"
export const ET = "et"
export const HE = "he"
export const HR = "hr"
export const HU = "hu"
export const LT = "lt"
export const LV = "lv"
export const PL = "pl"
export const RO = "ro"
export const SK = "sk"
export const SL = "sl"

export const localeNames = {
    [EN]: "English", // 英语
    [ZH_CN]: "简体中文",
    
} as Record<string, string>

export const defaultLocale = "zh-CN" as const

// 所有语言列表的标识代码，不含具体语言名称
export const locales = Object.keys(localeNames)
// console.log("当前语言列表",locales)

export const pathnames: Pathnames<typeof locales> = {
   
}

export type I18nLocales = { key: string; name: string }[]

export const getCurrentLocaleName = (locale: string) => {
    return localeNames[locale]
}

// 生成多语言的alternates
export function alternatesLanguage(subPath: string) {
    const path = process.env.UE_WEB_URL
    const languages: Record<string, string> = {}
    locales.forEach((lang) => {
        languages[lang] =
            lang === defaultLocale ? `${path}${subPath}` : `${path}/${lang}${subPath}`
    })
    return languages
}

export function alternatesCanonical(locale: string, subPath: string, page?: string) {
    const path = process.env.UE_WEB_URL
    const withPages = page ? `/${page}` : ''
    return `${path}${defaultLocale === locale ? "" : `/${locale}`}${subPath}${withPages}`
}

export const port = process.env.PORT || 8000
export const host = process.env.UE_WEB_URL
    ? `https://${process.env.UE_WEB_URL}`
    : `http://localhost:${port}`
